import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import EncuestaPreguntasForm from "./templates/encuesta-preguntas"

const EncuestaPreguntas = ({ location }) => (
  <Layout location={location}>
    <SEO title="Encuesta de satisfaccion" />
    <EncuestaPreguntasForm />
  </Layout>
)

export default EncuestaPreguntas